<template>
    <div>
        <div
            v-if="!altaTurno"
            :style='{"text-align":"center", "font-size":"8pt"}'
        >
            No hay alta para este paciente
        </div>
        <table
            v-if="altaTurno"
            :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal",}'
        >
            <tbody>
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'>
                        Fecha y hora de egreso
                    </td>
                </tr>
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"padding":"2px", "white-space": "pre-wrap"}}'>
                        <span>{{moment(datos.alta.dateCreated).format('dddd MMMM D, YYYY HH:mm')}}</span>
                    </td>
                </tr>
            </tbody>
            <tbody
                v-for="(itm, id) in items"
                :key="id"
            >

                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'>
                        {{itm.label}}
                    </td>
                </tr>
                <tr>
                    <td :style='{...estilos.tablaBorde, ...{"padding":"5px", "white-space": "pre-wrap"}}'>
                        <span v-if="datos.alta[itm.valor]">{{datos.alta[itm.valor]}}</span>
                        <span v-if="!datos.alta[itm.valor]">NINGUNO</span>
                    </td>
                </tr>
            </tbody>

        </table>

    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    data:()=>({
        items:[
            {label:'Signos y síntomas de alerta que ponen en riesgo la vida', valor:'sintomas'},
            {label:'Ministración de medicamentos y reacciones', valor:'medicamentos'},
            {label:'Control de citas', valor:'citas'},
            {label:'Tipo de dieta', valor:'dieta'},
            {label:'Manejo correcto de procedimientos', valor:'procedimientos'},
            {label:'Hábitos higiénicos', valor:'higiene'},
            {label:'Ejercicio/recreación', valor:'ejercicio'},
            {label:'Ejercer sus valores y creencias', valor:'valores'},
            {label:'Otros', valor:'otros'},
            {label:'Nombre y firma del familiar o tutor que recibe las recomendaciones e información', valor:'responsable'},
        ]
    }),

    computed:{
        altaTurno(){
            let activo = false
            if (this.turno && this.datos.alta && this.datos.alta.dateCreated){
                let fechaTurnoDe = this.moment(this.estaFecha+' '+this.$store.state.enfermeria.horarios[this.turno][0], 'DD-MM-YYYY HH:mm')
                let fechaTurnoA = this.moment(this.estaFecha+' '+this.$store.state.enfermeria.horarios[this.turno][0], 'DD-MM-YYYY HH:mm').add(this.$store.state.enfermeria.horarios[this.turno].length, 'hours')
                let fechaAlta = this.moment(this.datos.alta.dateCreated)

                //console.log(fechaTurnoDe)
                //console.log(fechaTurnoA)

                activo = fechaAlta.isBetween(fechaTurnoDe, fechaTurnoA)
            }
            return activo
        },
    },

}
</script>

<style>

</style>