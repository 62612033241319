export const printEnfe = {
    props:{
        //paciente:Number,
        //internamiento:Number,
        estaFecha:String,
        turno: String,
        //dialog:Boolean,
        datos:Object
    },
    data:()=>({
        /*
        horarios:{
            mat: [
                '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00',
            ],
            ves: [
                '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
            ],
            noc: [
                '21:00', '22:00', '23:00', '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
            ],
        },

        coloresTuro:{
            mat:'#81D4FA',
            ves:'#A5D6A7',
            noc:'#EF9A9A',
        },
        */
        estilos:{
            tablaBorde:{"border": "1px solid  #000","border-spacing": "0px"},
        },
    }),
}